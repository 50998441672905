'use strict';

var util = require('./util');
var format_url = require('./format_url');
var request = require('./request');

var StyleLayer = L.TileLayer.extend({

    options: {
        sanitizer: require('@mapbox/sanitize-caja')
    },

    initialize: function(_, options) {
        L.TileLayer.prototype.initialize.call(this, undefined, L.extend({}, options, {
            tileSize: 512,
            zoomOffset: -1,
            minNativeZoom: 0,
            tms: false
        }));
        this._url = this._formatTileURL(_);
        this._getAttribution(_);
    },

    _getAttribution: function(_) {
        var styleURL = format_url.style(_, this.options && this.options.accessToken);
        request(styleURL, L.bind(function(err, style) {
            if (err) {
                util.log('could not load Mapbox style at ' + styleURL);
                this.fire('error', {error: err});
            }
            var sources = [];
            for (var id in style.sources) {
                var source = style.sources[id].url.split('mapbox://')[1];
                sources.push(source);
            }
            request(format_url.tileJSON(sources.join(), this.options.accessToken), L.bind(function(err, json) {
                if (err) {
                    util.log('could not load TileJSON at ' + _);
                    this.fire('error', {error: err});
                } else if (json) {
                    util.strict(json, 'object');

                    this.options.attribution = this.options.sanitizer(json.attribution);

                    this._tilejson = json;
                    this.fire('ready');
                }
            }, this));
        }, this));
    },

    // disable the setUrl function, which is not available on mapbox tilelayers
    setUrl: null,

    _formatTileURL: function(style) {
        if (typeof style === 'string') {
            if (style.indexOf('mapbox://styles/') === -1) {
                util.log('Incorrectly formatted Mapbox style at ' + style);
                this.fire('error');
            }
            var ownerIDStyle = style.split('mapbox://styles/')[1];
            return format_url('/styles/v1/' + ownerIDStyle + '/tiles/{z}/{x}/{y}{r}', this.options.accessToken);
        } else if (typeof style === 'object') {
            return format_url('/styles/v1/' + style.owner + '/' + style.id + '/tiles/{z}/{x}/{y}{r}', this.options.accessToken);
        }
    }
});

module.exports.StyleLayer = StyleLayer;

module.exports.styleLayer = function(_, options) {
    return new StyleLayer(_, options);
};
