'use strict';

var util = require('./util'),
    format_url = require('./format_url'),
    feedback = require('./feedback'),
    request = require('./request');

// Low-level geocoding interface - wraps specific API calls and their
// return values.
module.exports = function(url, options) {
    if (!options) options = {};
    var geocoder = {};

    util.strict(url, 'string');

    if (url.indexOf('/') === -1) {
        url = format_url('/geocoding/v5/' + url + '/{query}.json', options.accessToken, 5);
    }

    function roundTo(latLng, precision) {
        var mult = Math.pow(10, precision);
        latLng.lat = Math.round(latLng.lat * mult) / mult;
        latLng.lng = Math.round(latLng.lng * mult) / mult;
        return latLng;
    }

    geocoder.getURL = function() {
        return url;
    };

    geocoder.queryURL = function(_) {
        var isArray = L.Util.isArray;
        var isObject = !(isArray(_) || typeof _ === 'string'),
            query = isObject ? _.query : _;

        if (isArray(query)) {
            var parts = [];
            for (var i = 0; i < query.length; i++) {
                parts[i] = encodeURIComponent(query[i]);
            }
            query = parts.join(';');
        } else {
            query = encodeURIComponent(query);
        }

        feedback.record({ geocoding: query });

        var url = L.Util.template(geocoder.getURL(), {query: query});

        if (isObject) {
            if (_.types) {
                if (isArray(_.types)) {
                    url += '&types=' + _.types.join();
                } else {
                    url += '&types=' + _.types;
                }
            }

            if (_.country) {
                if (isArray(_.country)) {
                    url += '&country=' + _.country.join();
                } else {
                    url += '&country=' + _.country;
                }
            }

            if (_.bbox) {
                if (isArray(_.bbox)) {
                    url += '&bbox=' + _.bbox.join();
                } else {
                    url += '&bbox=' + _.bbox;
                }
            }

            if (_.proximity) {
                var proximity = roundTo(L.latLng(_.proximity), 3);
                url += '&proximity=' + proximity.lng + ',' + proximity.lat;
            }

            if (typeof _.autocomplete === 'boolean') {
                url += '&autocomplete=' + _.autocomplete;
            }
        }

        return url;
    };

    geocoder.query = function(_, callback) {
        util.strict(callback, 'function');

        request(geocoder.queryURL(_), function(err, json) {
            if (json && (json.length || json.features)) {
                var res = {
                    results: json
                };
                if (json.features && json.features.length) {
                    res.latlng = [
                        json.features[0].center[1],
                        json.features[0].center[0]];

                    if (json.features[0].bbox) {
                        res.bounds = json.features[0].bbox;
                        res.lbounds = util.lbounds(res.bounds);
                    }
                }
                callback(null, res);
            } else callback(err || true);
        });

        return geocoder;
    };

    // a reverse geocode:
    //
    //  geocoder.reverseQuery([80, 20])
    geocoder.reverseQuery = function(_, callback) {
        var q = '';

        // sort through different ways people represent lat and lon pairs
        function normalize(x) {
            var latLng;
            if (x.lat !== undefined && x.lng !== undefined) {
                latLng = L.latLng(x.lat, x.lng);
            } else if (x.lat !== undefined && x.lon !== undefined) {
                latLng = L.latLng(x.lat, x.lon);
            } else {
                latLng = L.latLng(x[1], x[0]);
            }
            latLng = roundTo(latLng, 5);
            return latLng.lng + ',' + latLng.lat;
        }

        if (_.length && _[0].length) {
            for (var i = 0, pts = []; i < _.length; i++) {
                pts.push(normalize(_[i]));
            }
            q = pts.join(';');
        } else {
            q = normalize(_);
        }

        request(geocoder.queryURL(q), function(err, json) {
            callback(err, json);
        });

        return geocoder;
    };

    return geocoder;
};
